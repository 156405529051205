import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="min-h-screen bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-3xl mx-auto bg-white shadow-md rounded-lg p-8">
        <h1 className="text-3xl font-bold text-gray-900 mb-8 text-center">
          Privacy Policy
        </h1>

        <div className="space-y-6 text-gray-700">
          <p>
            At Markatlas Inkjet Technologies, we are committed to protecting the privacy and personal information of our users. 
            This Privacy Policy outlines how we collect, use, disclose, and safeguard your information when you visit our website 
            or use our services. By accessing or using markatlas.com, you signify your consent to the practices described 
            in this Privacy Policy. Please read this policy carefully to understand our practices regarding your information.
          </p>

          <section>
            <h2 className="text-2xl font-semibold text-gray-800 mt-6 mb-4">1. Information We Collect</h2>
            <p>
              We may collect both personally identifiable information and non-personally identifiable information from you 
              when you interact with our website or use our services. The types of information we may collect include:
            </p>
            <ul className="list-disc list-inside pl-4 mt-2 space-y-2">
              <li>
                <strong>Personal Information:</strong> This may include your name, email address, 
                mailing address, phone number, and other similar information.
              </li>
              <li>
                <strong>Transaction Information:</strong> We collect information related to your purchases, 
                including the products you buy and payment details.
              </li>
              <li>
                <strong>Log and Usage Data:</strong> We automatically collect information about your interactions 
                with our website, such as IP addresses, browser type, referring/exit pages, operating system, and timestamps.
              </li>
              <li>
                <strong>Cookies and Similar Technologies:</strong> We use cookies and similar tracking technologies 
                to track your activities on our website and enhance your user experience.
              </li>
            </ul>
          </section>

          <section>
            <h2 className="text-2xl font-semibold text-gray-800 mt-6 mb-4">2. How We Use Your Information</h2>
            <p>We use the information we collect for various purposes, including:</p>
            <ul className="list-disc list-inside pl-4 mt-2 space-y-2">
              <li>Providing and improving our services</li>
              <li>Personalizing your experience</li>
              <li>Processing your transactions</li>
              <li>Communicating with you</li>
              <li>Marketing and advertising our products and services</li>
              <li>Responding to your inquiries and providing customer support</li>
              <li>Protecting our legal rights and preventing fraudulent activities</li>
            </ul>
          </section>

          <section>
            <h2 className="text-2xl font-semibold text-gray-800 mt-6 mb-4">3. How We Disclose Your Information</h2>
            <p>
              We may disclose your information to third parties in the following circumstances:
            </p>
            <ul className="list-disc list-inside pl-4 mt-2 space-y-2">
              <li>With your consent or as otherwise necessary to fulfill a purpose for which you provide it.</li>
              <li>With our service providers and partners who assist us in operating our website and providing our services.</li>
              <li>In connection with a business transaction such as a merger, acquisition, or sale of assets.</li>
              <li>When required by law or in response to a valid legal request.</li>
            </ul>
          </section>

          <section>
            <h2 className="text-2xl font-semibold text-gray-800 mt-6 mb-4">4. Data Security</h2>
            <p>
              We implement reasonable security measures to protect your information from unauthorized access, 
              alteration, disclosure, or destruction. However, no method of transmission over the Internet or 
              electronic storage is completely secure, and we cannot guarantee absolute security.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold text-gray-800 mt-6 mb-4">5. Third-Party Links</h2>
            <p>
              Our website may contain links to third-party websites or services that are not operated by us. 
              Once you leave our website, we have no control over the privacy practices or content of these 
              third-party sites. We encourage you to review the privacy policies of any third-party websites you visit.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold text-gray-800 mt-6 mb-4">6. Children's Privacy</h2>
            <p>
              Our services are not directed to individuals under the age of 13. We do not knowingly collect 
              or solicit personal information from children. If we become aware that we have collected personal 
              information from a child without parental consent, we will take steps to remove that information from our servers.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold text-gray-800 mt-6 mb-4">7. Your Choices and Rights</h2>
            <p>
              You have certain choices and rights regarding the information we collect about you. You can review, 
              update, or delete your personal information by contacting us at support@markatlasinkjettechnologies.com. 
              You can also opt-out of receiving promotional communications from us by following the unsubscribe 
              instructions provided in the communication.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold text-gray-800 mt-6 mb-4">8. Changes to This Privacy Policy</h2>
            <p>
              We may update our Privacy Policy from time to time. Any changes we make will be posted on this page, 
              and the effective date will be revised accordingly. We encourage you to review this Privacy Policy 
              periodically for any updates.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold text-gray-800 mt-6 mb-4">9. Contact Us</h2>
            <p>
              If you have any questions, concerns, or requests regarding this Privacy Policy, please contact us at 
              <a 
                href="mailto:support@markatlasinkjettechnologies.com" 
                className="text-blue-600 hover:underline ml-2"
              >
                support@markatlasinkjettechnologies.com
              </a>
            </p>
          </section>

          <p className="mt-8 text-center text-gray-500">
            By using markatlasinkjettechnologies.com, you acknowledge that you have read and understood this Privacy Policy 
            and agree to its terms and conditions.
          </p>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;