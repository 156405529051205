import React, { useState } from 'react';
import { ChevronDown, ChevronUp } from 'lucide-react';

const TermsOfService = () => {
  const [openSection, setOpenSection] = useState(null);

  const toggleSection = (sectionIndex) => {
    setOpenSection(openSection === sectionIndex ? null : sectionIndex);
  };

  const sections = [
    {
      title: "Welcome to Markatlas Inkjet Technologies",
      content: (
        <p className="text-gray-700 leading-relaxed">
          These Terms of Service ("Terms") govern your access to and use of Markatlas Inkjet Technologies' 
          services, websites, and software (the "Services"), so please read them carefully.
        </p>
      )
    },
    {
      title: "Accepting These Terms",
      content: (
        <div className="space-y-4">
          <p>
            By accessing or using our Services, you agree to be bound by these Terms. If you don't agree 
            to these Terms, do not use the Services.
          </p>
          <p>
            These Terms create a legally binding contract between you and Markatlas Inkjet Technologies. 
            You acknowledge that you have read and understood these Terms and agree to be bound by them.
          </p>
        </div>
      )
    },
    {
      title: "Changes to These Terms",
      content: (
        <p>
          We may modify these Terms at any time. We will always post the most current version of these 
          Terms on our website. If a material change is made, we will notify you through our Services 
          or by sending you an email. By continuing to use our Services after any changes, you accept 
          the new Terms.
        </p>
      )
    },
    {
      title: "Privacy and Data Protection",
      content: (
        <div className="space-y-4">
          <p>
            Our Privacy Policy explains how we treat your personal data and protect your privacy when 
            you use our Services. By using our Services, you agree that Markatlas Inkjet Technologies 
            can use such data in accordance with our Privacy Policy.
          </p>
          <p>
            We may share certain aggregate or anonymized data with third parties, but we will never 
            sell your personal information to any third party.
          </p>
        </div>
      )
    },
    {
      title: "Refund Policy",
      content: (
        <div className="space-y-4">
          <p className="font-semibold text-red-600">
            Currently, Markatlas Inkjet Technologies does not offer refunds for any purchased services or products.
          </p>
          <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4">
            <p className="text-yellow-700">
              Important Considerations:
            </p>
            <ul className="list-disc list-inside pl-4 text-yellow-700 space-y-2 mt-2">
              <li>All sales are final</li>
              <li>No partial refunds will be issued</li>
              <li>We do not provide refunds for digital products or services after delivery</li>
              <li>Technical issues will be addressed through customer support</li>
            </ul>
          </div>
          <p className="italic text-gray-600">
            By making a purchase, you acknowledge and agree to these refund terms.
          </p>
        </div>
      )
    },
    {
      title: "User Rights and Responsibilities",
      content: (
        <div className="space-y-4">
          <p>To use our Services, you must:</p>
          <ul className="list-disc list-inside pl-4 space-y-2">
            <li>Be at least 13 years old</li>
            <li>Have the necessary legal capacity to enter into these Terms</li>
            <li>Provide accurate and current registration information</li>
            <li>Maintain the confidentiality of your account</li>
            <li>Accept responsibility for all activities under your account</li>
          </ul>
        </div>
      )
    },
    {
      title: "Intellectual Property Rights",
      content: (
        <div className="space-y-4">
          <p>
            All content, features, and functionality are and will remain the exclusive property of 
            Markatlas Inkjet Technologies and its licensors.
          </p>
          <p>Our trademarks and trade dress may not be used without our prior written permission.</p>
        </div>
      )
    },
    {
      title: "User Conduct",
      content: (
        <div>
          <p>When using our Services, you agree not to:</p>
          <ul className="list-disc list-inside pl-4 space-y-2 mt-4">
            <li>Violate any applicable local, state, national, or international law</li>
            <li>Infringe on the intellectual property rights of others</li>
            <li>Upload harmful or malicious code</li>
            <li>Attempt to gain unauthorized access to our systems</li>
            <li>Engage in any conduct that could damage, disable, or impair our infrastructure</li>
          </ul>
        </div>
      )
    },
    {
      title: "Limitation of Liability",
      content: (
        <p>
          To the maximum extent permitted by law, Markatlas Inkjet Technologies shall not be liable 
          for any indirect, incidental, special, consequential, or punitive damages arising out of 
          or relating to your use of our Services.
        </p>
      )
    },
    {
      title: "Governing Law",
      content: (
        <p>
          These Terms shall be governed by the laws of the jurisdiction where Markatlas Inkjet 
          Technologies operates, without regard to its conflict of law principles.
        </p>
      )
    },
    {
      title: "Contact Us",
      content: (
        <p>
          If you have any questions about these Terms, please contact us at:
          <a 
            href="mailto:support@markatlasinkjettechnologies.com" 
            className="text-blue-600 hover:underline ml-2"
          >
            support@markatlasinkjettechnologies.com
          </a>
        </p>
      )
    }
  ];

  return (
    <div className="min-h-screen bg-gray-50 py-6 sm:py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-4xl mx-auto bg-white shadow-xl rounded-2xl overflow-hidden">
        <div className="bg-blue-600 text-white py-6 px-6 text-center">
          <h1 className="text-3xl sm:text-4xl font-bold">
            Markatlas Inkjet Technologies
          </h1>
          <p className="text-lg sm:text-xl mt-2 opacity-80">
            Terms of Service
          </p>
        </div>

        <div className="p-4 sm:p-8">
          {sections.map((section, index) => (
            <div 
              key={index} 
              className="border-b last:border-b-0 border-gray-200 py-4"
            >
              <button 
                onClick={() => toggleSection(index)}
                className="w-full flex justify-between items-center text-left focus:outline-none"
              >
                <h2 className="text-lg sm:text-xl font-semibold text-gray-800">
                  {section.title}
                </h2>
                {openSection === index ? (
                  <ChevronUp className="text-gray-600" />
                ) : (
                  <ChevronDown className="text-gray-600" />
                )}
              </button>

              {openSection === index && (
                <div className="mt-4 text-gray-700 text-base sm:text-md">
                  {section.content}
                </div>
              )}
            </div>
          ))}
        </div>

        <div className="bg-gray-100 p-4 text-center text-sm text-gray-500">
          Last Updated: {new Date().toLocaleDateString()}
        </div>
      </div>
    </div>
  );
};

export default TermsOfService;