import React from 'react';
import { Cloud, Server } from 'lucide-react'; // Using relevant icons for Cloud and Server
import { motion } from 'framer-motion';

const PriceCard = ({ title, price, description, index }) => (
  <motion.div
    initial={{ opacity: 0, y: 50 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.5, delay: index * 0.1 }}
    className="bg-white p-6 rounded-lg shadow-lg hover:shadow-xl transition-all duration-300 hover:-translate-y-2 border-t-4 border-orange-500"
  >
    <div className="flex items-center mb-4">
      <div className="bg-blue-500 p-2 rounded-full mr-4">
        {title === 'Cloud Plan' ? (
          <Cloud className="w-6 h-6 text-white" />
        ) : (
          <Server className="w-6 h-6 text-white" />
        )}
      </div>
      <h3 className="text-xl font-bold text-blue-800">{title}</h3>
    </div>
    <p className="text-xl font-bold text-orange-500">{`₹${price}`}</p>
    <p className="text-gray-600">{description}</p>
  </motion.div>
);

const CloudPricingSection = () => {
  const pricingPlans = [
    {
      title: 'Cloud Plan',
      price: 25000, // Example price for Cloud Software Development
      description: 'Ideal for businesses transitioning to the cloud with scalable storage and computing power.'
    },
    {
      title: 'Hybrid Plan',
      price: 35000, // Example price for Hybrid cloud solution
      description: 'For organizations requiring a combination of on-premise and cloud resources with flexibility.'
    },
    {
      title: 'Enterprise Plan',
      price: 45000, // Example price for Enterprise cloud solution
      description: 'Full enterprise-grade cloud solution with dedicated support, higher security, and custom features.'
    }
  ];

  return (
    <section className="bg-gradient-to-b py-20">
      <div className="container mx-auto px-4">
        <motion.h2
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="text-4xl font-bold mb-12 text-center text-blue-800"
        >
          Cloud Software Development Pricing
        </motion.h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {pricingPlans.map((plan, index) => (
            <PriceCard key={index} {...plan} index={index} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default CloudPricingSection;
