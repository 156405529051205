import React from 'react';
import { DollarSign, Briefcase, UserCheck } from 'lucide-react'; // Icons for Consulting
import { motion } from 'framer-motion';

const consultingPlans = [
  {
    title: 'Basic IT Consulting',
    price: 20000, // Price in INR for basic services
    description: 'Ideal for startups and small businesses looking for basic IT guidance and strategic planning.',
    features: [
      'Initial IT infrastructure assessment',
      'Basic strategic IT planning',
      '1 consultation session per month',
      'Email and phone support'
    ]
  },
  {
    title: 'Standard IT Consulting',
    price: 30000, // Price in INR for standard services
    description: 'A comprehensive IT consulting package for medium-sized businesses with more in-depth strategic support.',
    features: [
      'IT infrastructure analysis and optimization',
      'Developing detailed IT strategy and roadmap',
      'Bi-weekly consultation sessions',
      'Priority email and phone support'
    ]
  },
  {
    title: 'Premium IT Consulting',
    price: 50000, // Price in INR for premium services
    description: 'Full-scale IT consulting services for large enterprises, including custom solutions, security audits, and more.',
    features: [
      'Custom IT solutions and system integration',
      'Comprehensive security audits and risk management',
      'Monthly in-depth strategy sessions',
      '24/7 dedicated support and consultation'
    ]
  }
];

const ITConsultingPricingSection = () => {
  return (
    <section className="bg-gradient-to-b py-20">
      <div className="container mx-auto px-4">
        <motion.h2
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="text-4xl font-bold mb-12 text-center text-blue-800"
        >
          IT Consulting Services Pricing
        </motion.h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {consultingPlans.map((plan, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
              className="bg-white p-6 rounded-lg shadow-lg hover:shadow-xl transition-all duration-300 hover:-translate-y-2 border-t-4 border-orange-500"
            >
              <div className="flex items-center mb-4">
                <div className="bg-blue-500 p-2 rounded-full mr-4">
                  <Briefcase className="w-6 h-6 text-white" />
                </div>
                <h3 className="text-xl font-bold text-blue-800">{plan.title}</h3>
              </div>
              <p className="text-xl font-bold text-orange-500">{`₹${plan.price}`}</p>
              <p className="text-gray-600 mb-4">{plan.description}</p>
              <ul className="text-gray-700">
                {plan.features.map((feature, idx) => (
                  <motion.li
                    key={idx}
                    initial={{ opacity: 0, x: -20 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ duration: 0.5, delay: idx * 0.1 }}
                    className="mb-2 flex items-start"
                  >
                    <div className="w-2 h-2 bg-blue-500 rounded-full mt-2 mr-3 flex-shrink-0" />
                    <p>{feature}</p>
                  </motion.li>
                ))}
              </ul>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default ITConsultingPricingSection;
